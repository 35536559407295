import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import { useStore } from '@/composables/useStore'
import { AppState } from '@/enums/AppState'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import axios, { AxiosError } from 'axios'

const Login = () => import('@/views/Login.vue')
const Home = () => import('@/views/Home.vue')

const Error = () => import('@/views/errorpages/Error.vue')
const PageNotFound = () => import('@/views/errorpages/NotFound.vue')

const WidgetResults = () => import('@/views/widgets/WidgetResults.vue')
const WidgetYourDay = () => import('@/views/widgets/WidgetYourDay.vue')
const WidgetNotes = () => import('@/views/widgets/WidgetNotes.vue')
const WidgetPage = () => import('@/views/widgets/WidgetPage.vue')

const Settings = () => import('@/views/Settings.vue')
const Notes = () => import('@/views/Notes.vue')
const Verdiepen = () => import('@/views/Verdiepen.vue')
const Documenten = () => import('@/views/Documenten.vue')
const YourDay = () => import('@/views/your-day/YourDay.vue')
const Digibord = () => import('@/views/Digibord.vue')
const Planning = () => import('@/views/planning/Planning.vue')
const Results = () => import('@/views/results/Results.vue')
const CategoryDetail = () => import('@/views/results/CategoryDetail.vue')
const CategoryVolgDetail = () => import('@/views/results/CategoryVolgDetail.vue')
const CandidateDetail = () => import('@/views/results/CandidateDetail.vue')

const PlanningOnboardingModal = () => import('@/views/Planning-OnboardingModal.vue')
const Onboarding = () => import('@/views/Onboarding.vue')
const PlanningOnboarding = () => import('@/views/onboarding-subpages/Planning.vue')
const WelcomeOnboarding = () => import('@/views/onboarding-subpages/Welcome.vue')
const StudydaysOnboarding = () => import('@/views/onboarding-subpages/Studydays.vue')
const TestsOnboarding = () => import('@/views/onboarding-subpages/Tests.vue')
const ActivitiesOnboarding = () => import('@/views/onboarding-subpages/Activities.vue')
const HolidaysOnboarding = () => import('@/views/onboarding-subpages/Holidays.vue')
const SubjectsOnboarding = () => import('@/views/onboarding-subpages/Subjects.vue')

const IframeTester = () => import('@/views/IframeTester.vue')

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/error',
        name: 'Error',
        component: Error,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/verdiepen',
        name: 'verdiepen',
        component: Verdiepen,
    },
    {
        path: '/documenten',
        name: 'documenten',
        component: Documenten,
    },
    {
        path: '/resultaten',
        name: 'results',
        component: Results,
    },
    {
        path: '/instellingen',
        name: 'settings',
        component: Settings,
        children: [
            {
                path: 'aanpassen',
                component: PlanningOnboardingModal,
                children: [
                    //these entries will be shown inside the nested router view, an empty path in the child means it is the default visible route.
                    {
                        path: 'schoolvakanties',
                        name: 'onboarding-schoolholidays-planning',
                        component: HolidaysOnboarding,
                    },
                    {
                        path: 'studiedagen',
                        name: 'onboarding-studydays-planning',
                        component: StudydaysOnboarding,
                    },
                    {
                        path: 'activiteiten',
                        name: 'onboarding-activities-planning',
                        component: ActivitiesOnboarding,
                    },
                    {
                        path: 'toetsen',
                        name: 'onboarding-tests-planning',
                        component: TestsOnboarding,
                    },
                    {
                        path: 'vakken',
                        name: 'onboarding-subjects-planning',
                        component: SubjectsOnboarding,
                    },
                    {
                        path: '',
                        name: 'onboarding-planning-planning',
                        component: PlanningOnboarding,
                    },
                ],
            },
        ],
    },
    {
        path: '/planning',
        name: 'planning',
        component: Planning,
    },
    {
        path: '/resultaten/leerling/:userId/:catId/:volgCatId',
        name: 'candidateDetail',
        component: CandidateDetail,
    },
    {
        path: '/resultaten/vak/:type/:catId',
        name: 'categoryDetail',
        component: CategoryDetail,
    },
    {
        path: '/resultaten/volgen/:volgType/:catId',
        name: 'categoryVolgDetail',
        component: CategoryVolgDetail,
    },
    {
        path: '/notities',
        name: 'notities',
        component: Notes,
    },
    {
        path: '/jouw-lesdag',
        name: 'lesdag',
        component: YourDay,
    },
    {
        path: '/digibord',
        name: 'digibord',
        component: Digibord,
    },
    {
        path: '/onboarding',
        component: Onboarding,
        children: [
            //these entries will be shown inside the nested router view, an empty path in the child means it is the default visible route.
            {
                path: '',
                name: 'onboarding-welcome',
                component: WelcomeOnboarding,
            },
            {
                path: 'schoolvakanties',
                name: 'onboarding-schoolholidays',
                component: HolidaysOnboarding,
            },
            {
                path: 'studiedagen',
                name: 'onboarding-studydays',
                component: StudydaysOnboarding,
            },
            {
                path: 'activiteiten',
                name: 'onboarding-activities',
                component: ActivitiesOnboarding,
            },
            {
                path: 'toetsen',
                name: 'onboarding-tests',
                component: TestsOnboarding,
            },
            {
                path: 'vakken',
                name: 'onboarding-subjects',
                component: SubjectsOnboarding,
            },
            {
                path: 'planning',
                name: 'onboarding-planning',
                component: PlanningOnboarding,
            },
        ],
    },
    {
        path: '/widget/moo',
        name: 'widgets',
        component: WidgetPage,
        redirect: '/widget/moo/resultaten',
        children: [
            {
                path: 'jouwlesdag',
                name: 'widget-lesdag',
                component: WidgetYourDay,
            },
            {
                path: 'resultaten',
                name: 'widget-resultaten',
                component: WidgetResults,
            },
            {
                path: 'notities',
                name: 'widget-notities',
                component: WidgetNotes,
            },
        ],
    },
    {
        path: '/iframetester',
        name: 'iframetester',
        component: IframeTester,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Notfound',
        component: PageNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
})

router.afterEach((to, from) => {
    const { appcuesStore, notesStore, appStore } = useStore()

    if (appStore.getters.value.appState === AppState.LOGGEDIN) {
        notesStore.actions.getUnreadNotes()
        notesStore.actions.getUnread()
    }

    if (process.env.NODE_ENV !== 'development') {
        appcuesStore.actions.appcuesIdentify()

        const appInsights = new ApplicationInsights({
            config: {
                connectionString: window.__env.appInsights.connectionString,
                disablePageUnloadEvents: ['unload'],
            },
        })
        appInsights.loadAppInsights()
        appInsights.trackPageView()

        appInsights?.trackPageView()
    }
})

router.beforeEach((to, from, next) => {
    const { appStore, userStore } = useStore()

    if (to.name === 'Login' || to.name === 'styleguide' || to.name === 'Error') {
        next()
    } else if (to.name === 'widgets' || to.name === 'widget-lesdag' || to.name === 'widget-resultaten' || to.name === 'widget-notities') {
        appStore.actions
            .initializeWidgets()
            .then(() => {
                next()
            })
            .catch((e: AxiosError) => {
                if (e.response?.status === 401) {
                    const windowroute = window.location.href
                    console.info('windowroute', windowroute)

                    let pagina = 'Resultaten'
                    if (windowroute.includes('/widget/moo/jouwlesdag')) {
                        pagina = 'JouwLesdag'
                    } else if (windowroute.includes('/widget/moo/notities')) {
                        pagina = 'Notities'
                    }

                    console.info('redirecting to', pagina)
                    window.location.href = axios.defaults.baseURL + `auth/startmoo?pagina=${pagina}`
                } else {
                    return next({ name: 'Login' })
                }
            })
    } else {
        appStore.actions
            .initializeApp()
            .then(() => {
                const user = userStore.getters.value.getUser
                const regio = userStore.getters.value.getRegio

                if (from.matched.some((route) => route.name === 'onboarding-welcome') && !to.matched.some((route) => route.name === 'onboarding-welcome') && (!user.onboardingAfgerond || !regio)) {
                    return next(false)
                } else if (!from.matched.some((route) => route.name === 'onboarding-welcome') && !to.matched.some((route) => route.name === 'onboarding-welcome') && (!user.onboardingAfgerond || !regio)) {
                    return next({ name: 'onboarding-welcome' })
                } else {
                    next()
                }
            })
            .catch(() => {
                if (to.name === 'Login' || to.name === 'styleguide' || to.name === 'Error') {
                    next()
                } else {
                    return next({ name: 'Login' })
                }
            })
    }
})

export default router
