<template>
    <div>
        <div class="d-flex align-items-center mb-4 mb-xl-9">
            <svg
                height="42px"
                version="1.1"
                viewBox="0 0 34 42"
                width="34px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <g
                    fill="none"
                    fill-rule="evenodd"
                    id="icon/note/pencil"
                    stroke="none"
                    stroke-width="1"
                >
                    <g
                        fill-rule="nonzero"
                        id="icon/pencil"
                        transform="translate(17.000000, 21.000000) rotate(-144.000000) translate(-17.000000, -21.000000) translate(13.000000, -1.000000)"
                    >
                        <path
                            d="M4.01240816,44 C1.81012245,44 0.0248163265,42.0361633 0.0248163265,39.613649 L0.0248163265,36.0430939 L4.01240816,36.0430939 L4.01240816,44 Z"
                            fill="#F84280"
                            id="Path"
                        ></path>
                        <path
                            d="M8,39.613649 C8,42.0361633 6.21469388,44 4.01240816,44 L4.01240816,36.0430939 L8,36.0430939 L8,39.613649 Z"
                            fill="#DD316C"
                            id="Path"
                        ></path>
                        <polygon
                            fill="#FACE48"
                            id="Path"
                            points="0.0248163265 32.5186041 0.0248163265 8.75267755 4.01240816 6.44869388 4.01240816 24.2219228 4.01240816 32.5186041 2.22244898 33.4556245"
                        ></polygon>
                        <polygon
                            fill="#FFBB33"
                            id="Path"
                            points="4.01240816 32.5186041 4.01240816 6.44869388 8 8.75267755 8 32.5186041 6.00620408 33.1726776"
                        ></polygon>
                        <path
                            d="M0.0248163265,8.35883265 C0.0248163265,6.87405714 0.444163265,5.45438367 1.23771429,4.25300408 L4.01240816,0.0794693878 L4.98228571,5.2513551 L4.01240816,8.75267755 L0.0248163265,8.75267755 L0.0248163265,8.35883265 Z"
                            fill="#FCDD79"
                            id="Path"
                        ></path>
                        <path
                            d="M4.01240816,0.0794693878 L6.78710204,4.25300408 C7.58057143,5.45420408 8,6.87387755 8,8.35883265 L8,8.75276735 L4.01240816,8.75276735 L4.01240816,0.0794693878 Z"
                            fill="#FACE48"
                            id="Path"
                        ></path>
                        <polygon
                            fill="#FFBB33"
                            id="Path"
                            points="4.01240816 36.0430939 0.0248163265 36.0430939 0.0248163265 32.5186041 4.01240816 32.5186041"
                        ></polygon>
                        <polygon
                            fill="#EEA81D"
                            id="Path"
                            points="4.01240816 32.5186041 8 32.5186041 8 36.0430939 4.01240816 36.0430939"
                        ></polygon>
                    </g>
                </g>
            </svg>
            <h4 class="ml-5 mb-0">Schrijf een notitie</h4>
        </div>
        <div
            class="d-flex flex-column w-100"
            v-if="notesStore.getters.value.getCurrentNote && notesStore.getters.value.getCurrentNote.onderwerpLabel"
        >
            <div
                class="d-flex"
                :class="[noteLocked ? '' : 'mb-8']"
            >
                <div
                    class="create-note-body"
                    :class="notesStore.getters.value.getCombinedRecommendedTags.length > 0 ? 'w-100' : ''"
                >
                    <div class="template-widget-select mb-3">
                        <v-select
                            :clearable="false"
                            :options="notesStore.getters.value.getNoteSubjects"
                            @input="onSubjectChange"
                            :disabled="noteLocked"
                            label="naam"
                            name="selected_item"
                            v-model="notesStore.getters.value.getCurrentNote.onderwerpLabel"
                        >
                        </v-select>
                    </div>
                    <rte
                        :note="notesStore.getters.value.getCurrentNote"
                        :tags="tags"
                        :mentions="mentions"
                        class="create-note-rte mb-4"
                        placeholder="Gebruik @naam of #onderwerp om je notitie te koppelen aan een leerling, groep of onderwerp"
                        v-model="noteHtml"
                        :disabled="noteLocked"
                    ></rte>
                </div>
                <div
                    v-if="notesStore.getters.value.getCombinedRecommendedTags.length > 0 && !noteLocked"
                    class="tag-section"
                >
                    <div class="tag-section__title mt-2 mb-6">Voorgestelde tags:</div>
                    <div class="tag-section__tags w-100">
                        <button
                            :class="{
                                'btn-active-tag': tag.active,
                                removable: tag.canBeRemoved,
                            }"
                            :key="index"
                            @click="setTagActiveState(tag)"
                            class="btn rounded-pill d-flex mb-2 tag-section__tags__tag"
                            v-for="(tag, index) in notesStore.getters.value.getCombinedRecommendedTags"
                        >
                            <div class="tag-section__tags__tag__text">{{ tag.text }}</div>
                            <div
                                @click.stop="deleteRecommendedTag(tag)"
                                class="active-tag-icon"
                            >
                                <svg
                                    class="feather feather-x-circle"
                                    fill="#A69BD1"
                                    height="16"
                                    stroke="#FFFFFF"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    v-if="tag.active"
                                    viewBox="0 0 24 21"
                                    width="16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="10"
                                    ></circle>
                                    <line
                                        x1="15"
                                        x2="9"
                                        y1="9"
                                        y2="15"
                                    ></line>
                                    <line
                                        x1="9"
                                        x2="15"
                                        y1="9"
                                        y2="15"
                                    ></line>
                                </svg>
                                <svg
                                    class="feather feather-x-circle"
                                    fill="#FFFFFF"
                                    height="16"
                                    stroke="#A69BD1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    v-else
                                    viewBox="0 0 24 21"
                                    width="16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="10"
                                    ></circle>
                                    <line
                                        x1="15"
                                        x2="9"
                                        y1="9"
                                        y2="15"
                                    ></line>
                                    <line
                                        x1="9"
                                        x2="15"
                                        y1="9"
                                        y2="15"
                                    ></line>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-if="noteLocked"
                class="d-flex justify-content-start align-items-center mt-6 mb-2"
            >
                <Warning
                    class="mr-2 text-danger"
                    style="height: 3rem; width: 3rem"
                />
                <span>Deze notitie kan momenteel niet bewerkt worden. Hij wordt al bewerkt door: {{ notesStore.getters.value.getCurrentNoteExtendedInfo?.lockedByUser }}</span>
            </div>

            <div
                v-if="!noteLocked"
                class="plan-note"
            >
                <div
                    class="plan-note__planned"
                    v-if="noteScheduled"
                >
                    <h4>{{ notePlannedText }}</h4>

                    <div class="d-flex align-items-center">
                        <div
                            class="plan-note__planned__moment"
                            @click="planNote"
                        >
                            <div v-html="selectedNoteMomentLabel"></div>
                        </div>
                        <div
                            class="plan-note__planned__remove"
                            @click="removeNoteMoment()"
                        >
                            <svg
                                class="feather feather-x-circle"
                                fill="#A69BD1"
                                height="20"
                                stroke="#FFFFFF"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 21"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                ></circle>
                                <line
                                    x1="15"
                                    x2="9"
                                    y1="9"
                                    y2="15"
                                ></line>
                                <line
                                    x1="9"
                                    x2="15"
                                    y1="9"
                                    y2="15"
                                ></line>
                            </svg>
                        </div>
                    </div>
                </div>

                <template v-else>
                    <h4 class="font-weight-bold">Notitie inplannen</h4>
                    <p class="plan-note-subtitle">Met notitie inplannen kun je een notitie koppelen aan een dag of specifieke les(sen).</p>
                    <button
                        class="plan-note-cta"
                        @click="planNote"
                        :disabled="noteLocked"
                    >
                        <span>klik hier om de notitie in te plannen</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                                fill="rgba(71,49,135,1)"
                            ></path>
                        </svg>
                    </button>
                </template>
            </div>
            <div
                class="custom-control custom-checkbox note-pin"
                v-if="!noteLocked"
            >
                <input
                    class="custom-control-input"
                    id="note-pin"
                    type="checkbox"
                    :disabled="noteLocked"
                    v-model="notesStore.getters.value.getCurrentNote.vastgepind"
                />
                <label
                    class="custom-control-label"
                    for="note-pin"
                >
                    <div>
                        <div class="font-weight-bold">Notitie vastpinnen</div>
                        <div class="subtitle">Wanneer je dit icoon aanklikt, kun je deze notitie makkelijk terugvinden op de Notities pagina.</div>
                    </div>
                    <div class="note-pin__icon">
                        <svg
                            height="60px"
                            v-if="!notesStore.getters.value.getCurrentNote.vastgepind"
                            version="1.1"
                            viewBox="0 0 60 60"
                            width="60px"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                fill="none"
                                fill-rule="evenodd"
                                id="icon/pin/big/empty"
                                stroke="none"
                                stroke-width="1"
                            >
                                <g>
                                    <circle
                                        cx="30"
                                        cy="30"
                                        fill="#FFF8EE"
                                        id="Oval-Copy-3"
                                        r="30"
                                    ></circle>
                                    <path
                                        d="M35.7709976,30.9525036 L34.4864187,20.9471003 C36.7973544,20.3952863 38.0558177,19.4555929 38.0507492,18.2680602 C38.0456806,17.0805275 36.8131144,17.0812107 35.8704809,17.0430441 C34.9278475,17.0048775 24.0639052,16.992473 23.7832353,17.0043171 C23.5025654,17.0161612 21.7282561,17.0105377 21.7333247,18.1980705 C21.7383932,19.3856032 23.0058273,20.3362131 25.3209833,20.9077873 L24.1227482,30.9015544 C21.4595862,31.8223945 19.9927138,32.9176161 20,34.6311137 C20.0073406,36.3446113 22.4012882,36.3781709 23.3599861,36.2765377 C23.999118,36.2087823 25.5219339,36.0547013 27.9284338,35.8142947 C29.0310477,40.9771027 29.7218478,43.5591052 30.0008343,43.5603022 C30.2792736,43.5614962 30.957884,40.9853675 32.0366656,35.831916 C34.8182035,36.1521718 36.2993205,36.3040521 36.4800165,36.2875568 C36.7510605,36.262814 40.0073829,36.745766 40,35.032268 C39.9925919,33.31877 38.4411287,31.8952328 35.7709976,30.9525036 Z"
                                        fill-rule="nonzero"
                                        id="Path"
                                        stroke="#F84280"
                                        stroke-width="2"
                                        transform="translate(30.000000, 30.280151) rotate(-330.000000) translate(-30.000000, -30.280151) "
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <svg
                            height="60px"
                            v-else
                            version="1.1"
                            viewBox="0 0 60 60"
                            width="60px"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                fill="none"
                                fill-rule="evenodd"
                                id="icon/pin/big/filled"
                                stroke="none"
                                stroke-width="1"
                            >
                                <g
                                    id="icon/pin/empty-copy-2"
                                    transform="translate(0.500000, 0.000000)"
                                >
                                    <circle
                                        cx="30"
                                        cy="30"
                                        fill="#FFF8EE"
                                        id="Oval-Copy-3"
                                        r="30"
                                    ></circle>
                                    <path
                                        d="M35.7709976,30.9525036 L34.4864187,20.9471003 C36.7973544,20.3952863 38.0558177,19.4555929 38.0507492,18.2680602 C38.0456806,17.0805275 36.8131144,17.0812107 35.8704809,17.0430441 C34.9278475,17.0048775 24.0639052,16.992473 23.7832353,17.0043171 C23.5025654,17.0161612 21.7282561,17.0105377 21.7333247,18.1980705 C21.7383932,19.3856032 23.0058273,20.3362131 25.3209833,20.9077873 L24.1227482,30.9015544 C21.4595862,31.8223945 19.9927138,32.9176161 20,34.6311137 C20.0073406,36.3446113 22.4012882,36.3781709 23.3599861,36.2765377 C23.999118,36.2087823 25.5219339,36.0547013 27.9284338,35.8142947 C29.0310477,40.9771027 29.7218478,43.5591052 30.0008343,43.5603022 C30.2792736,43.5614962 30.957884,40.9853675 32.0366656,35.831916 C34.8182035,36.1521718 36.2993205,36.3040521 36.4800165,36.2875568 C36.7510605,36.262814 40.0073829,36.745766 40,35.032268 C39.9925919,33.31877 38.4411287,31.8952328 35.7709976,30.9525036 Z"
                                        fill="#F84280"
                                        fill-rule="nonzero"
                                        id="Path"
                                        transform="translate(30.000000, 30.280151) rotate(-330.000000) translate(-30.000000, -30.280151) "
                                    ></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </label>
            </div>
            <div class="b-sidebar-footer d-flex flex-column align-items-center py-6 pt-xl-10 pb-xl-20">
                <button
                    :disabled="saveNoteDisabled"
                    class="btn btn-purple-gradient w-100 mb-5"
                    v-on:click="addOrUpdateNote"
                    v-if="!noteLocked"
                >
                    <div
                        v-if="saveNoteLoading"
                        class="spinner-border text-white"
                        role="status"
                        style="height: 1rem; width: 1rem; border-width: 2px"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                    <template v-else> Opslaan </template>
                </button>
                <router-link
                    @click="onShowAllNotes"
                    class="text-primary-light"
                    to="/notities"
                    ><u>Bekijk alle notities</u></router-link
                >
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { format, isSameDay } from 'date-fns'
import { nl as nlLocale } from 'date-fns/locale'
import { computed, ref } from 'vue'

import Warning from '@/components-svg/Warning.vue'
import Rte from '@/components/Notes/RteNotities.vue'
import { useStore } from '@/composables/useStore'
import { TagType } from '@/enums/TagType'
import { convertUTCtoLocal } from '@/helpers/convertTimezone'
import { ITagRecommended, ITagVM } from '@/interfaces/INote'
import { ILabel } from '@/interfaces/IPlanning'
import { emitter } from '@/services/emitter'
import { SELECTED_NOTE_MOMENT_TYPE } from '@/store/modules/notesModule'
import { ScheduleType } from './ScheduleNote.vue'

const emit = defineEmits<{
    (eventName: 'plan-note', payload: ScheduleType): void
}>()

const { notesStore, homeStore } = useStore()

const saveNoteLoading = ref(false)

const noteScheduled = computed(() => {
    const currentNote = notesStore.getters.value.getCurrentNote
    return currentNote.vakgebiedLabelId || currentNote.activiteitLabelId || currentNote.afspraken.length || currentNote.ingeplandeActiviteiten.length || currentNote.planningsdatum?.length
})

const noteHtml = computed({
    get() {
        return notesStore.getters.value.getCurrentNoteHtml
    },
    set(html: string) {
        notesStore.commits.setCurrentNoteHtml(html)
    },
})

const tags = computed((): ITagVM[] => {
    const tags = notesStore.getters.value.getAllTags.map((tag) => {
        return {
            id: tag.getagdeLabelId || tag.geTagdeGebruikerId || tag.id,
            name: tag.text,
            displayname: tag.text,
            type: '#',
        }
    })

    return tags.sort((a, b) => a.name.localeCompare(b.name))
})

const mentions = computed(() => {
    return notesStore.getters.value.getNoteCandidatesAllGroups
})

const selectedNoteMomentType = computed(() => {
    return notesStore.getters.value.getSelectedNoteMomentType
})

const notePlannedText = computed(() => {
    switch (notesStore.getters.value.getSelectedNoteMomentType) {
        case SELECTED_NOTE_MOMENT_TYPE.DAG:
            return 'Ingepland op:'
        case SELECTED_NOTE_MOMENT_TYPE.VAK:
            return 'Gekoppeld aan vak:'
        case SELECTED_NOTE_MOMENT_TYPE.LES:
            return 'Ingepland voor les:'
        default:
            return 'Ingepland:'
    }
})

const unlockNote = () => {
    const currentNoteExtendedInfo = notesStore.getters.value.getCurrentNoteExtendedInfo
    if (currentNoteExtendedInfo?.locked && currentNoteExtendedInfo?.lockedByCurrentUser && currentNoteExtendedInfo.id) {
        notesStore.actions.unlockNote(currentNoteExtendedInfo.id)
    }
}

const onShowAllNotes = () => {
    unlockNote()
    notesStore.commits.resetCurrentNote()
    notesStore.commits.resetCurrentNoteMoment()
    notesStore.commits.setSidebarState(false)
}

const setTagActiveState = (tag: ITagRecommended) => {
    const html = document.createElement('div')
    html.innerHTML = noteHtml.value

    if (!html.firstChild) {
        const paragraph = document.createElement('p')
        html.appendChild(paragraph)
    }

    const rteContent = html.firstChild //should be the P tag with all the content of the editor

    if (tag.active) {
        let tagsInRte

        if (tag.tagType === TagType.LEERLING) {
            tagsInRte = html.querySelectorAll('.mention')

            tagsInRte.forEach((t) => {
                if ((t as HTMLElement).dataset.tag === tag.geTagdeGebruikerId?.toString()) {
                    t.remove()
                }
            })
        } else {
            tagsInRte = html.querySelectorAll('.tag')

            tagsInRte.forEach((t) => {
                if ((t as HTMLElement).dataset.tag === tag.getagdeLabelId?.toString()) {
                    t.remove()
                } else if ((t as HTMLElement).dataset.name === tag.text.toString()) {
                    t.remove()
                }
            })
        }
        noteHtml.value = html.innerHTML
        notesStore.commits.setCurrentNoteHtml(html.innerHTML)
    } else {
        const tagToAppend = document.createElement('span')
        tagToAppend.setAttribute('data-name', tag.text)
        tagToAppend.setAttribute('data-displayname', tag.text)

        if (tag.tagType === TagType.LEERLING) {
            tagToAppend.classList.add('mention')
            tagToAppend.setAttribute('data-tag', tag.geTagdeGebruikerId?.toString() || '-1')
            tagToAppend.innerText = '@' + tag.text
        } else {
            tagToAppend.classList.add('tag')
            tagToAppend.setAttribute('data-tag', tag.getagdeLabelId?.toString() || '-1')
            tagToAppend.innerText = '#' + tag.text
        }

        rteContent?.appendChild(tagToAppend)

        notesStore.commits.setCurrentNoteHtml(html.innerHTML)
        noteHtml.value = html.innerHTML
    }

    notesStore.commits.setRecommendedTagActiveState(tag)
}

const planNote = () => {
    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.DAG) {
        emit('plan-note', 'Dag')
    } else {
        emit('plan-note', 'Les')
    }
}

const selectedNoteMomentLabel = computed((): string => {
    if (notesStore.getters.value.getExtendedNoteInfoLoading) {
        return 'Wordt geladen...'
    }

    //Notitie ingepland op meerdere dagen
    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.DAG && notesStore.getters.value.getNoteMomentDate && notesStore.getters.value.getNoteMomentDate.length > 1) return `Deze notitie is ingepland op ${notesStore.getters.value.getNoteMomentDate.length} dagen`

    //Notitie ingepland op 1 dag
    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.DAG && notesStore.getters.value.getNoteMomentDate && notesStore.getters.value.getNoteMomentDate.length === 1) return formatDate(notesStore.getters.value.getNoteMomentDate[0])

    // Notitie ingepland op 1 vak
    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.VAK && notesStore.getters.value.getNoteMomentLabel) return notesStore.getters.value.getNoteMomentLabel.naam

    // Notitie ingeplandop meerdere lessen
    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.LES && notesStore.getters.value.getSelectedNoteMomentLessonCount > 1) return `Deze notitie is ingepland voor ${notesStore.getters.value.getSelectedNoteMomentLessonCount} lessen`

    // Notitie ingepland op 1 les
    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.LES && notesStore.getters.value.getSelectedNoteMomentLessonCount === 1 && notesStore.getters.value.getCurrentNoteLesInfo) {
        const lesInfo = notesStore.getters.value.getCurrentNoteLesInfo
        return lesInfo ? `${lesInfo.naamVak ? `${lesInfo.naamVak} </br>` : ''} ${lesInfo.naamLes ? `${lesInfo.naamLes} </br>` : ''} ${formatDate(new Date(lesInfo.datumTijd), true, true)}` : ''
    }

    if (selectedNoteMomentType.value === SELECTED_NOTE_MOMENT_TYPE.LES && notesStore.getters.value.getSelectedNoteMomentLessonCount === 1 && notesStore.getters.value.getCurrentNoteExtendedInfo) {
        const extendedInfo = notesStore.getters.value.getCurrentNoteExtendedInfo
        const moment = extendedInfo.afspraken[0] ?? extendedInfo.ingeplandeActiviteiten[0]
        return moment ? `${moment.naamVak ? `${moment.naamVak} </br>` : ''} ${moment.naamLes ? `${moment.naamLes} </br>` : ''} ${format(new Date(moment.datumTijd), 'EEEE d MMMM HH:mm', { locale: nlLocale })}` : ''
    }

    return ''
})

const addOrUpdateNote = async () => {
    if (notesStore.getters.value.getCurrentNote.id === 0) {
        await addNote()
    } else {
        await updateNote()
    }
}

const onSubjectChange = (subject: ILabel) => {
    if (subject.naam.toLowerCase() === 'duo-overdracht') {
        notesStore.getters.value.getCurrentNote.vastgepind = true
    }
}

const formatDate = (date: string | Date | undefined, withTime: boolean = false, ignoreToday: boolean = false) => {
    if (!date) return ''
    if (isSameDay(new Date(date), new Date()) && !ignoreToday) return 'vandaag'
    const formatString = withTime ? 'EEEE d MMMM HH:mm' : 'EEEE d MMMM'

    return format(convertUTCtoLocal(new Date(date)), formatString, { locale: nlLocale })
        .split('.')
        .join('')
}

const addNote = async () => {
    saveNoteLoading.value = true
    try {
        await notesStore.actions.createNote(notesStore.getters.value.getCurrentNote)
        emitter.emit('note-added', notesStore.getters.value.getCurrentNote)
        notesStore.commits.setSidebarState(false)

        await notesStore.actions.getRecommendedTags()
        homeStore.commits.setHomeLoading(true)

        await notesStore.actions.getNotes()
        homeStore.commits.setHomeLoading(false)
    } catch (error) {
        saveNoteLoading.value = false
    } finally {
        saveNoteLoading.value = false
    }
}

const updateNote = async () => {
    saveNoteLoading.value = true
    try {
        await notesStore.actions.updateNote(notesStore.getters.value.getCurrentNote)
        emitter.emit('note-updated', notesStore.getters.value.getCurrentNote)
        notesStore.commits.setSidebarState(false)
        await notesStore.actions.getNotes()
        await notesStore.actions.getRecommendedTags()
        unlockNote()
    } catch (error) {
        saveNoteLoading.value = false
    } finally {
        saveNoteLoading.value = false
    }
}

//Als iemand op kruisje bij tag klikt
const deleteRecommendedTag = async (tag: ITagRecommended) => {
    if (tag.active) {
        setTagActiveState(tag)
    }

    await notesStore.actions.deleteRecommendedTag(tag.id)
    await notesStore.actions.getRecommendedTags()
}

const removeNoteMoment = () => {
    notesStore.commits.resetCurrentNoteMoment()
}

const noteLocked = computed(() => {
    if (!notesStore.getters.value.getCurrentNoteExtendedInfo) return false
    return notesStore.getters.value.getCurrentNoteExtendedInfo.locked && !notesStore.getters.value.getCurrentNoteExtendedInfo.lockedByCurrentUser
})

const saveNoteDisabled = computed(() => {
    if (noteLocked.value) return true
    if (saveNoteLoading.value) return true
    return notesStore.getters.value.getCurrentNote.onderwerpLabel && notesStore.getters.value.getCurrentNote.onderwerpLabel.labelId === -1
})
</script>
