import { getMonth, getYear, isBefore, isSameMonth, isSameWeek, startOfMonth, startOfWeek, sub } from 'date-fns'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { formatNL } from '@/helpers/formatDate'
import { dateSorter, stringSorter } from '@/helpers/sorters'
import { IFilter } from '@/interfaces/IFilter'
import { IExtendedNoteInfo, IGroupedMentionUsers, INote, INoteCandidate, INotePlanLes, INoteRelevant, INoteSubjectLabel, ITag } from '@/interfaces/INote'
import { notesService } from '@/services/noteService'
import { settingsService } from '@/services/settingsService'

import { ILesdag } from '@/interfaces/ILesdag'
import { notify } from '@kyvg/vue3-notification'
import { TagType } from './../../enums/TagType'
import { convertNoteHTML } from './../../helpers/convertNoteHtml'
import { IEventAfspraakWithAssets } from './../../interfaces/ILesdagSpeciaal'
import { INoteCollection, INoteCollectionOld, INoteWithLabel, ITagRecommended } from './../../interfaces/INote'
import { IAfspraakPostModel, ILabel, IPlanItem } from './../../interfaces/IPlanning'

export const SELECTED_NOTE_MOMENT_TYPE = {
    VAK: 0,
    LES: 1,
    DAG: 2,
} as const

export type ObjectValues<T> = T[keyof T]

export type SelectedNoteMomentType = ObjectValues<typeof SELECTED_NOTE_MOMENT_TYPE>

class DefaultNote implements INoteWithLabel {
    id = 0
    text = ''
    htmlText = ''
    vastgepind = false
    onderwerpLabel = {
        labelId: -1,
        naam: 'kies een onderwerp',
        kleurHexCode: '',
        externId: '',
    }
    toegevoegdDoor = ''
    gearchiveerd = false
    gedearchiveerd = false
    planningsdatum = []
    vakgebiedLabelId = null
    activiteitLabelId = null
    afspraken = []
    ingeplandeActiviteiten = []
    onderwerpLabelId = -1
}

class NoteState {
    notes: INoteWithLabel[] = []
    noteCandidates: INoteCandidate[] = []
    noteSubjectes: ILabel[] = []
    recommendedTags: ITagRecommended[] = []
    pageSpecificTags: ITagRecommended[] = []
    allTags: ITag[] = []
    pinnedOnly = false
    showArchived = false
    tagfilters: IFilter[] = []
    keywords: string[] = []
    searchText = ''
    currentNote: INoteWithLabel | INoteRelevant = new DefaultNote()
    sidebarOpen = false
    notificationVisible = false
    sidebarPlanNoteOnly = false
    notificationText = '<b>Je notitie is opgeslagen!</b> <span>Je kunt hem terugvinden op de Notities pagina.</span>'
    currentNotePlanItem: null | INotePlanLes = null
    currentNoteLabel: null | ILabel = null
    noteLesInfo: null | INotePlanLes = null
    extendedNoteInfo: null | IExtendedNoteInfo = null
    noteMomentVakgebied: null | number = null
    noteCandidatesAllGroups: IGroupedMentionUsers[] = []
    unreadNotesCount: number = 0
    unreadNotes: INote[] = []
    showUnread = false
    setGetExtendedNoteInfoLoading = false
}

const getters: GetterTree<NoteState, any> = {
    getNotificationHtml(store) {
        return () => store.notificationText
    },
    getNotificationState(store) {
        return () => store.notificationVisible
    },
    getSidebarState(store): () => boolean {
        return () => store.sidebarOpen
    },
    getCurrentNote(store): () => INoteWithLabel | INoteRelevant {
        return () => store.currentNote
    },
    getCurrentNoteHTML(store): () => string {
        return () => store.currentNote.htmlText || ''
    },
    getSearchText(store): () => string {
        return () => store.searchText
    },
    getKeywords(store): () => string[] {
        const matches: string[] = []
        store.notes.forEach((note) => {
            if (note.text) {
                const hastags = note.htmlText?.match(/#\w+/gm)?.map((s) => s.substr(1))
                hastags ? hastags.forEach((word) => matches.push(word)) : null

                const mentions = note.htmlText?.match(/@\w+/gm)?.map((s) => s.substr(1))
                mentions ? mentions.forEach((word) => matches.push(word)) : null
            }
        })
        return () => matches.sort()
    },
    getFilters(store): () => IFilter[] {
        return () => store.tagfilters
    },
    pinnedOnly(store): () => boolean {
        return () => store.pinnedOnly
    },
    showArchived(store): () => boolean {
        return () => store.showArchived
    },
    getNotes(store): () => INoteWithLabel[] {
        return () => store.notes
    },
    getFilteredNotes(store): () => INoteWithLabel[] {
        const filter = store.tagfilters.find((filter) => filter.isActive)

        const filterednotes = store.notes
            .filter((note) => tagFilterFunc(note, store.pinnedOnly, filter))
            .filter((note) => archivedFilterFunc(note, store.showArchived))
            .filter((note) => unreadFilterFunc(note, store.unreadNotes, store.showUnread))
            .filter((note) => searchFilterFunc(note, store.searchText))

        return () => filterednotes
    },
    getNotesByDate(store): () => INoteCollection {
        const week = startOfWeek(new Date())
        const lastWeek = sub(startOfWeek(new Date()), { weeks: 1 })
        const month = startOfMonth(new Date())

        const notes: INoteCollection = {
            thisWeek: {
                headerTitle: 'Deze week',
                visible: true,
                notes: [] as INoteWithLabel[],
            },
            lastWeek: {
                headerTitle: 'Afgelopen week',
                visible: true,
                notes: [] as INoteWithLabel[],
            },
            thisMonth: {
                headerTitle: 'Deze maand',
                visible: false,
                notes: [] as INoteWithLabel[],
            },
        }

        const filter = store.tagfilters.find((filter) => filter.isActive)

        const filterednotes = store.notes
            .filter((note) => tagFilterFunc(note, store.pinnedOnly, filter))
            .filter((note) => archivedFilterFunc(note, store.showArchived))
            .filter((note) => unreadFilterFunc(note, store.unreadNotes, store.showUnread))
            .filter((note) => searchFilterFunc(note, store.searchText))

        filterednotes.forEach((note) => {
            if (!note.datum) return

            const d = new Date(note.datum)

            if (isSameWeek(d, week)) {
                notes.thisWeek.notes.push(note)
            } else if (isSameWeek(d, lastWeek)) {
                notes.lastWeek.notes.push(note)
            } else if (isSameMonth(d, month)) {
                notes.thisMonth.notes.push(note)
            }
        })
        return () => notes
    },
    getNotesByOlderDate(store): () => INoteCollectionOld {
        const month = startOfMonth(new Date())
        const notes: INoteCollectionOld = {}

        const filter = store.tagfilters.find((filter) => filter.isActive)

        const filterednotes = store.notes
            .filter((note) => tagFilterFunc(note, store.pinnedOnly, filter))
            .filter((note) => archivedFilterFunc(note, store.showArchived))
            .filter((note) => unreadFilterFunc(note, store.unreadNotes, store.showUnread))
            .filter((note) => searchFilterFunc(note, store.searchText))

        filterednotes
            .sort((a, b) =>
                dateSorter({
                    a: a.datum,
                    b: b.datum,
                    asc: false,
                })
            )
            .forEach((note) => {
                if (!note.datum) return
                const d = new Date(note.datum)

                if (isBefore(d, month)) {
                    const newMonth = getMonth(d)
                    const newYear = getYear(d)

                    if (notes[`${newMonth}-${newYear}`] && notes[`${newMonth}-${newYear}`].notes) {
                        notes[`${newMonth}-${newYear}`].notes.push(note)
                    } else {
                        notes[`${newMonth}-${newYear}`] = {
                            headerTitle: `${formatNL(new Date(d), 'MMMM')} ${formatNL(new Date(d), 'yyyy')}`,
                            visible: store.searchText ? true : false,
                            notes: [],
                        }
                        notes[`${newMonth}-${newYear}`].notes.push(note)
                    }
                }
            })
        return () => notes
    },
    getNoteSubjects(store): () => ILabel[] {
        return () => store.noteSubjectes.sort((a, b) => stringSorter({ a: a.naam, b: b.naam, asc: true }))
    },
    getNoteCandidates(store): () => INoteCandidate[] {
        return () => store.noteCandidates
    },
    getRecommendedTags(store): () => ITagRecommended[] {
        return () => store.recommendedTags
    },
    getPageSpecificRecommendedTags(store): () => ITagRecommended[] {
        return () => store.pageSpecificTags
    },
    getCombinedRecommendedTags(store): () => ITagRecommended[] {
        return () => [...store.pageSpecificTags, ...store.recommendedTags].slice(0, 4)
    },
    getAllTags(store): () => ITag[] {
        return () => store.allTags
    },
    getSidebarPlanNoteOnly(store): () => boolean {
        return () => store.sidebarPlanNoteOnly
    },
    getNoteMomentDate(store) {
        return () => store.currentNote.planningsdatum
    },
    getNoteMomentVaklabelId(store) {
        return () => ('vakgebiedLabelId' in store.currentNote ? store.currentNote.vakgebiedLabelId : undefined)
    },
    getNoteMomentActiviteitLabelId(store) {
        return () => store.currentNote.activiteitLabelId
    },
    getSelectedNoteMomentType(store) {
        if (store.currentNote.planningsdatum && store.currentNote.planningsdatum.length > 0) return () => SELECTED_NOTE_MOMENT_TYPE.DAG
        else if ('vakgebiedLabelId' in store.currentNote && store.currentNote.vakgebiedLabelId && store.currentNoteLabel && !store.currentNotePlanItem) return () => SELECTED_NOTE_MOMENT_TYPE.VAK
        else if (store.currentNote.afspraken.length || store.currentNote.ingeplandeActiviteiten.length) return () => SELECTED_NOTE_MOMENT_TYPE.LES
        else return () => null
    },
    getSelectedNoteMomentLessonCount(store) {
        return () => store.currentNote.afspraken.length + store.currentNote.ingeplandeActiviteiten.length
    },
    getNoteMomentPlanItem(store) {
        return () => store.currentNotePlanItem
    },
    getNoteMomentLabel(store) {
        return () => store.currentNoteLabel
    },
    getCurrentNoteLesInfo(store) {
        return () => store.noteLesInfo
    },
    getCurrentNoteExtendedInfo(store) {
        return () => store.extendedNoteInfo
    },
    getNoteMomentVakgebied(store) {
        return () => store.noteMomentVakgebied
    },
    getNoteCandidatesAllGroups(store) {
        return () => store.noteCandidatesAllGroups
    },
    getUnreadNotesCount(store) {
        return () => store.unreadNotesCount
    },
    getUnreadNotes(store) {
        return () => store.unreadNotes
    },
    getShowUnread(store) {
        return () => store.showUnread
    },
    getExtendedNoteInfoLoading(store) {
        return () => store.setGetExtendedNoteInfoLoading
    },
}

const mutations: MutationTree<NoteState> = {
    setNotificationState(store, payload: boolean) {
        store.notificationVisible = payload
    },
    setNotificationHtml(store, payload: string) {
        store.notificationText = payload
    },
    setSidebarState(store, payload: boolean) {
        if (payload === false) {
            document.body.style.overflow = 'visible'
            store.sidebarPlanNoteOnly = false
        } else {
            document.body.style.overflow = 'hidden'
        }

        store.sidebarOpen = payload
    },
    setPlanNoteSidebarState(store) {
        store.sidebarPlanNoteOnly = true
    },
    setCurrentNote(store, payload: INoteWithLabel | INoteRelevant) {
        store.currentNote = payload
    },
    resetCurrentNote(store) {
        store.currentNote = new DefaultNote()
    },
    setCurrentNoteHTML(store, payload: string) {
        store.currentNote.htmlText = payload
        const text = convertNoteHTML(payload)
        store.currentNote.text = text
    },
    setNotesSearch(store, payload: string) {
        store.searchText = payload
    },
    setKeywords(store, payload) {
        store.keywords = payload
    },
    setTagFilter(state, payload: IFilter) {
        state.tagfilters.forEach((filter) => {
            if (filter.id === payload.id) {
                filter.isActive = !filter.isActive
            } else {
                filter.isActive = false
            }
        })
    },
    setTagFilters(state, payload: IFilter[]) {
        state.tagfilters = payload
    },
    setPinnedOnly(state, payload: boolean) {
        state.pinnedOnly = payload
    },
    setShowArchived(state, payload: boolean) {
        state.showArchived = payload
    },
    setNotes(state, payload: INoteWithLabel[]) {
        state.notes = payload.map((note) => {
            return {
                ...note,
                htmlTextVM: note.htmlText ? note.htmlText : '',
            }
        })
    },
    setNoteSubjects(state, payload: ILabel[]) {
        state.noteSubjectes = payload
    },
    setNoteCandidates(state, payload: INoteCandidate[]) {
        state.noteCandidates = payload
    },
    setAllNoteLeerlingen(state, payload: IGroupedMentionUsers[]) {
        state.noteCandidatesAllGroups = payload
    },
    setAllTags(state, payload: ITag[]) {
        state.allTags = payload
    },
    setRecommendedTags(state, payload: ITag[]) {
        const tags: ITagRecommended[] = payload.map((tag) => {
            return {
                ...tag,
                active: false,
                displayname: tag.text,
                tagType: tag.geTagdeGebruikerId ? TagType.LEERLING : TagType.VAK,
                canBeRemoved: true,
            }
        })
        state.recommendedTags = tags
    },
    setRecommendedTagActiveState(state, payload: ITagRecommended) {
        const pageSpecificIndex = state.pageSpecificTags.findIndex((tag) => tag.id === payload.id && tag.tagType === payload.tagType)
        const tagIndex = state.recommendedTags.findIndex((tag) => tag.id === payload.id && tag.tagType === payload.tagType)
        if (tagIndex > -1) {
            state.recommendedTags[tagIndex].active = !payload.active
        }
        if (pageSpecificIndex > -1) {
            state.pageSpecificTags[pageSpecificIndex].active = !payload.active
        }
    },
    setPageSpecificRecommendedTags(state, payload: ITagRecommended[]) {
        state.pageSpecificTags = payload
    },
    removePageSpecificRecommendedTags(state) {
        state.pageSpecificTags = []
    },
    pinNote(state, payload: INoteWithLabel | INoteRelevant) {
        const index = state.notes.findIndex((note) => note.id === payload.id)
        index > -1 ? (state.notes[index].vastgepind = payload.vastgepind) : null
    },
    setCurrentNoteMomentDate(state, payload: Date[]) {
        state.currentNote.planningsdatum = payload
    },
    setCurrentNoteMomentPlanItem(state, payload: INotePlanLes | null) {
        if ('vakgebiedLabelId' in state.currentNote) {
            state.currentNote.vakgebiedLabelId = payload?.vakgebiedId
        }
        state.currentNote.activiteitLabelId = payload?.activiteitLabelId
        state.currentNotePlanItem = payload
    },
    setCurrentNoteVak(state, payload: ILabel | null) {
        if ('vakgebiedLabelId' in state.currentNote) {
            state.currentNote.vakgebiedLabelId = payload?.labelId
        }

        state.currentNoteLabel = payload
    },
    setCurrentNoteLessons(state, payload: INotePlanLes[] | null) {
        state.noteMomentVakgebied = payload?.[0]?.vakgebiedId ?? null
        const verbondenAfspraken = payload?.filter((les) => les.ingeplandeActiviteitId && les.isAfspraak).map((les) => les.ingeplandeActiviteitId)
        const verbondenActiviteiten = payload?.filter((les) => les.ingeplandeActiviteitId && !les.isAfspraak).map((les) => les.ingeplandeActiviteitId)

        if (verbondenAfspraken) {
            state.currentNote.afspraken = verbondenAfspraken
        }

        if (verbondenActiviteiten) {
            state.currentNote.ingeplandeActiviteiten = verbondenActiviteiten
        }
    },
    setCurrentNoteLesInfo(state, payload: INotePlanLes | null) {
        state.noteLesInfo = payload
    },
    resetCurrentNoteMoment(state) {
        if ('vakgebiedLabelId' in state.currentNote) {
            state.currentNote.vakgebiedLabelId = null
        }

        state.currentNote.planningsdatum = []
        state.currentNote.activiteitLabelId = null
        state.currentNote.afspraken = []
        state.currentNote.ingeplandeActiviteiten = []
        state.currentNotePlanItem = null
        state.currentNoteLabel = null
        state.noteLesInfo = null
        state.extendedNoteInfo = null
        state.noteMomentVakgebied = null
    },
    setExtendedNoteInfo(state, payload: IExtendedNoteInfo) {
        state.extendedNoteInfo = payload
    },
    setUnreadNotesCount(state, payload: number) {
        state.unreadNotesCount = payload
    },
    setUnreadNotes(state, payload: INote[]) {
        state.unreadNotes = payload
    },
    setShowUnread(state, payload: boolean) {
        state.showUnread = payload
    },
    setGetExtendedNoteInfoLoading(state, payload: boolean) {
        state.setGetExtendedNoteInfoLoading = payload
    },
}

const actions: ActionTree<NoteState, any> = {
    getNotes({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            notesService
                .getNotes(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setNotes', data.data as INote[])
                        resolve(data.data as INote[])
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    createNote({ rootState, commit }, payload: INoteWithLabel) {
        return new Promise((resolve, reject) => {
            const note: INoteWithLabel = {
                ...payload,
                onderwerpLabelId: payload.onderwerpLabel.labelId,
                ingeplandeActiviteiten: rootState.notes.currentNote.ingeplandeActiviteiten,
            }

            if (payload.htmlText) {
                notesService
                    .addNote(rootState.groups.selectedGroup.id, note)
                    .then((data) => {
                        if (data.status == 401) {
                            reject('unauthorized')
                        } else {
                            resolve(data.data)
                            commit('resetCurrentNote')
                            commit('setNotificationHtml', '<b>Je notitie is opgeslagen!</b> <span>Je kunt hem terugvinden op de Notities pagina.</span>')
                            commit('setNotificationState', true)
                        }
                    })
                    .catch((e: Error) => {
                        genericError(e)
                        reject(e)
                    })
            }
        })
    },
    updateNote({ rootState, commit }, payload: INoteWithLabel | INoteRelevant) {
        const note: INote = {
            ...payload,
            onderwerpLabelId: payload.onderwerpLabel.labelId,
        }
        return new Promise((resolve, reject) => {
            notesService
                .updateNote(rootState.groups.selectedGroup.id, note)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setNotificationHtml', '<b>Je notitie is opgeslagen!</b> <span>Je kunt hem terugvinden op de Notities pagina.</span>')
                        commit('setNotificationState', true)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    deleteNote({ rootState, commit }, payload) {
        return new Promise((resolve, reject) => {
            // this is hacky cause backend is not working properly. Fix this when backend is fixed
            notesService
                .deleteSharedNote(rootState.groups.selectedGroup.id, payload)
                .then((data) => {
                    // wanneer dit slaagt is het een shared note en is hij aan deze kant verwijderd
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setNotificationHtml', '<b>Je notitie is verwijderd!</b>')
                        commit('setNotificationState', true)
                        resolve(data.data)
                    }
                })
                .finally(() => {
                    notesService
                        .deleteNote(rootState.groups.selectedGroup.id, payload)
                        .then((data) => {
                            if (data.status == 401) {
                                reject('unauthorized')
                            } else {
                                commit('setNotificationHtml', '<b>Je notitie is verwijderd!</b>')
                                commit('setNotificationState', true)
                                resolve(data.data)
                            }
                        })
                        .catch((e: Error) => {
                            genericError(e)
                            reject(e)
                        })
                })
        })
    },
    deleteRecommendedTag({ rootState }, payload) {
        return new Promise((resolve, reject) => {
            notesService
                .deleteRecommendedTag(rootState.groups.selectedGroup.id, payload)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getNoteSubjects({ commit }) {
        return new Promise((resolve, reject) => {
            notesService
                .getNoteSubjects()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setNoteSubjects', data.data as INoteSubjectLabel[])
                        resolve(data.data as INoteSubjectLabel[])
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getNoteCandidates({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            const leerkrachten = settingsService.getLeerkrachten()
            const leerlingen = notesService.getNoteLeerlingen(rootState.groups.selectedGroup.id)

            Promise.all([leerkrachten, leerlingen])
                .then(([lkn, lln]) => {
                    if (lkn.data.status == 401 || lln.data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setNoteCandidates', [...lkn.data, ...lln.data])
                        resolve([...lkn.data, ...lln.data])
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getAllNoteGebruikers({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            const leerlingen = notesService
                .getAllNoteGebruikers()
                .then((lln) => {
                    if (lln.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setAllNoteLeerlingen', lln.data)
                        resolve(lln.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getRecommendedTags({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            notesService
                .getRecommendedNoteTags(rootState.groups.selectedGroup.id)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setRecommendedTags', data.data as INote[])
                        resolve(data.data as INote[])
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getAllTags({ commit }) {
        return new Promise((resolve, reject) => {
            notesService
                .getAllNoteTags()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setAllTags', data.data as INote[])
                        resolve(data.data as INote[])
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    setCurrentNoteMomentDate({ commit }, payload: Date | null) {
        commit('resetCurrentNoteMoment')
        commit('setCurrentNoteMomentDate', payload)
    },
    setCurrentNoteMomentPlanItem({ commit }, payload: IPlanItem | null) {
        commit('resetCurrentNoteMoment')
        commit('setCurrentNoteMomentPlanItem', payload)
    },
    setCurrentNoteVak({ commit }, payload: ILabel | null) {
        commit('resetCurrentNoteMoment')
        commit('setCurrentNoteVak', payload)
    },
    setCurrentNoteLessons({ commit }, payload: INotePlanLes[] | null) {
        commit('resetCurrentNoteMoment')
        commit('setCurrentNoteLessons', payload)
    },
    openCreateNoteForLesson({ commit }, payload: IPlanItem) {
        const note: INotePlanLes[] = [
            {
                isAfspraak: false,
                ingeplandeActiviteitId: payload.id,
                vakgebiedId: payload.vakgebiedId,
                activiteitLabelId: payload.activiteitLabelId,
                naamVak: payload.naamVak,
                methodeId: payload.methodeId,
                naamMethode: payload.naamMethode,
                naamLes: payload.naamLes,
                datumTijd: payload.datumTijd,
            },
        ]

        commit('resetCurrentNoteMoment')
        commit('setCurrentNoteLesInfo', note[0])
        commit('setCurrentNoteLessons', note)
        commit('setSidebarState', true)
    },
    openCreateNoteForLessonYourday({ commit }, payload: ILesdag) {
        const note: INotePlanLes[] = [
            {
                isAfspraak: false,
                ingeplandeActiviteitId: payload.activiteit.id,
                vakgebiedId: payload.activiteit.vakgebiedId,
                activiteitLabelId: payload.activiteit.activiteitLabelId,
                naamVak: payload.activiteit.naamVak,
                methodeId: payload.activiteit.methodeId,
                naamMethode: payload.activiteit.naamMethode,
                naamLes: payload.activiteit.naamLes,
                datumTijd: payload.activiteit.datumTijd,
            },
        ]

        commit('resetCurrentNoteMoment')
        commit('setCurrentNoteLesInfo', note[0])
        commit('setCurrentNoteLessons', note)
        commit('setSidebarState', true)
    },
    openCreateNoteForLessonAfspraak({ commit }, payload: IAfspraakPostModel) {
        if (payload.id && payload.vakgebiedLabelId) {
            const note: INotePlanLes[] = [
                {
                    isAfspraak: true,
                    ingeplandeActiviteitId: payload.id,
                    vakgebiedId: payload.vakgebiedLabelId,
                    activiteitLabelId: payload.activiteitLabelId,
                    naamVak: '', //TODO: Is naamvak hier nodig?
                    methodeId: payload.methodeBlokId,
                    naamMethode: '', //TODO: Is deze nodig?,
                    naamLes: '', // TODO: Is deze nodig?
                    datumTijd: payload.datum,
                },
            ]

            commit('resetCurrentNoteMoment')
            commit('setCurrentNoteLesInfo', note[0])
            commit('setCurrentNoteLessons', note)
            commit('setSidebarState', true)
        }
    },
    openCreateNoteForAfspraakYourday({ commit }, payload: IEventAfspraakWithAssets) {
        if (payload.id && payload.afspraakDetails.vakgebiedLabelId) {
            const note: INotePlanLes[] = [
                {
                    isAfspraak: true,
                    ingeplandeActiviteitId: payload.afspraakDetails.activiteitLabelId,
                    vakgebiedId: payload.afspraakDetails.vakgebiedLabelId,
                    activiteitLabelId: payload.afspraakDetails.activiteitLabelId,
                    naamVak: '', //TODO: Is naamvak hier nodig?
                    methodeId: payload.afspraakDetails.methodeBlokId,
                    naamMethode: '', //TODO: Is deze nodig?,
                    naamLes: payload.afspraakDetails.lesNaam,
                    datumTijd: payload.datumStart,
                },
            ]

            commit('resetCurrentNoteMoment')
            commit('setCurrentNoteLesInfo', note[0])
            commit('setCurrentNoteLessons', note)
            commit('setSidebarState', true)
        }
    },
    getCurrentNoteExtendedInfo({ commit }, payload: number) {
        return new Promise((resolve, reject) => {
            commit('setGetExtendedNoteInfoLoading', true)
            notesService
                .getExtendedNoteInfo(payload)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setExtendedNoteInfo', data.data as IExtendedNoteInfo)
                        resolve(data.data as IExtendedNoteInfo)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
                .finally(() => {
                    commit('setGetExtendedNoteInfoLoading', false)
                })
        })
    },
    unlockNote({ commit }, noteId: number) {
        return new Promise((resolve, reject) => {
            notesService
                .unlockNote(noteId)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getUnread({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            notesService
                .getUnread()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setUnreadNotesCount', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    getUnreadNotes({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            notesService
                .getUnreadNotes()
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        commit('setUnreadNotes', data.data)
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
    markNoteAsRead({ commit, rootState }, tagId: number) {
        return new Promise((resolve, reject) => {
            notesService
                .markAsRead(tagId)
                .then((data) => {
                    if (data.status == 401) {
                        reject('unauthorized')
                    } else {
                        resolve(data.data)
                    }
                })
                .catch((e: Error) => {
                    genericError(e)
                    reject(e)
                })
        })
    },
}

const tagFilterFunc = (note: INoteWithLabel, pinnedOnly: boolean, filter?: IFilter) => {
    if (pinnedOnly && note.vastgepind) {
        if (filter && note.onderwerpLabel.naam === filter.subject) {
            return true
        } else if (!filter) {
            return true
        } else {
            return false
        }
    } else if (!pinnedOnly) {
        if (filter && note.onderwerpLabel.naam === filter.subject) {
            return true
        } else if (!filter) {
            return true
        } else {
            return false
        }
    }
}

const archivedFilterFunc = (note: INoteWithLabel, showArchived: boolean) => {
    return !showArchived ? note.gearchiveerd === showArchived : true
}

const unreadFilterFunc = (note: INoteWithLabel, unreadNotes: INote[], showUnread: boolean) => {
    if (!showUnread) return true
    return unreadNotes.findIndex((_note) => _note.id === note.id) !== -1
}

const searchFilterFunc = (note: INoteWithLabel, searchText: string) => {
    return note.htmlText
        ?.replace(/(<([^>]+)>)/gi, '')
        .replace(/\s\s+/g, ' ')
        .toLowerCase()
        .includes(searchText.toLowerCase())
}

const genericError = (e: Error) => {
    notify({
        group: 'requests',
        title: 'Foutmelding',
        type: 'error',
        text: `Er is iets misgegaan. Error: ${e.message}`,
    })
}

export default {
    state: new NoteState(),
    getters: getters,
    mutations: mutations,
    actions: actions,
}
